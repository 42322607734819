import { useNavigation } from "@react-navigation/core";
import {
  Card,
  Layout,
  Text,
  Divider,
  Button,
  Avatar,
  Spinner,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { MyServer, NewServer } from "../components/SingleServer";
import supabase from "../supabase";
import StyleSheet from "react-native-media-query";

const logos = {
  BTC: require("../assets/logos.crypto/BTC.png"),
  ETH: require("../assets/logos.crypto/ETH.png"),
  BCH: require("../assets/logos.crypto/BCH.png"),
  BNB: require("../assets/logos.crypto/BNB.png"),
};

export default function MyAccountScreen() {
  const navigation = useNavigation();
  const [servers, setServers] = useState([]);
  const [loadingMyServers, setLoadingMyServers] = useState(false);
  const [myServers, setMyServers] = useState([]);

  const initMyServers = async () => {
    setLoadingMyServers(true);

    const uu = await supabase.auth.session();

    const response = await fetch("/api/servers", {
      headers: {
        "x-app-api": uu?.access_token,
      },
    });

    setLoadingMyServers(false);

    if (response.status == 200) {
      const data = await response.json();
      setMyServers(data);
    }

    return;

    // if (freshServers) {
    //   setMyServers(freshServers);
    // }
  };

  useEffect(() => {
    initMyServers();
  }, []);

  return (
    <Layout style={{ flex: 1 }}>
      <ScrollView>
        {loadingMyServers ? (
          <View
            style={{
              minHeight: 30,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 30
            }}
          >
            <Spinner size="giant" />
          </View>
        ) : myServers.length >= 1 ? (
          <>
            <Text category="h6">Your Servers</Text>
            <View style={{ height: 20 }} />
            <View
              style={{ flexDirection: "row", flexWrap: "wrap", marginLeft: 10 }}
            >
              {myServers.map((server) => (
                <View
                  key={server.id}
                  style={styles.containServer}
                  dataSet={{ media: ids.containServer }}
                >
                  <MyServer navigation={navigation} server={server} />
                </View>
              ))}
            </View>
          </>
        ) : (
          <View
            style={{
              minHeight: 150,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
              <Text category="h5" style={{ textAlign: "center"}}>
                You are not backing any servers yet
              </Text>
              <View style={{ height: 10 }} />
              <Button onPress={() => navigation.navigate("Servers")}>Find A Server</Button>
          </View>
        )}
      </ScrollView>
    </Layout>
  );
}

const { ids, styles } = StyleSheet.create({
  containServer: {
    paddingRight: 10,
    width: "100%",
    "@media (min-width: 768px)": {
      width: "50%",
    },
    "@media (min-width: 992px)": {
      width: "33.333333%",
    },
  },
});
