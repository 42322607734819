/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName, Image, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import DashboardScreen from "../screens/DashboardScreen";
import IntroScreen from "../screens/IntroScreen";
import LoginScreen from "../screens/LoginScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import { authLinking, guestLinking } from "./LinkingConfiguration";
import ServersScreen from "../screens/ServersScreen";
import ServerScreen from "../screens/ServerScreen";
import JoinServerScreen from "../screens/JoinServerScreen";
import AboutScreen from "../screens/AboutScreen";
import { Button, useTheme } from "@ui-kitten/components";
import useAuth from "../hooks/useAuth";
import { createMainTabNavigator } from "./MainTabNavigator";
import MyAccountScreen from "../screens/MyAccountScreen";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const { isLoggedIn } = useAuth();
  const theme = useTheme();

  return (
    <NavigationContainer
      linking={authLinking}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DarkTheme.colors,
          background: theme["background-basic-color-1"],
          card: theme["background-basic-color-2"],
        }
      }}
    >
      <RootNavigator isLoggedIn={isLoggedIn} />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator();

function RootNavigator({ isLoggedIn = false }) {
  return (
    <Stack.Navigator>
      {isLoggedIn ? (
        <>
          <Stack.Screen
            name="Root"
            component={BottomTabNavigator}
            options={({ navigation }) => ({
              headerRight: () => (
                <View
                  style={{
                    marginRight: 15,
                  }}
                >
                  <Button
                    onPress={() => navigation.navigate("About")}
                    size="tiny"
                    status="primary"
                  >
                    How it works
                  </Button>
                </View>
              ),
              headerTitle: () => {
                return (
                  <Image
                    source={require("../assets/images/header.png")}
                    style={{ width: 80, height: 35 }}
                    resizeMode="contain"
                  />
                );
              },
            })}
          />
          <Stack.Screen
            name="Server"
            component={ServerScreen}
            options={{ title: "Server" }}
          />
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: "Oops!" }}
          />
          <Stack.Group screenOptions={{ presentation: "modal" }}>
            <Stack.Screen
              name="JoinServer"
              component={JoinServerScreen}
              options={{ title: "Join A Server" }}
            />
            <Stack.Screen
              name="About"
              component={AboutScreen}
              options={{ title: "How it works" }}
            />
          </Stack.Group>
        </>
      ) : (
        <Stack.Screen
          component={LoginScreen}
          name="LoginScreen"
          options={{ headerShown: false, title: "Login" }}
        />
      )}
    </Stack.Navigator>
  );
}

const BottomTab = createMainTabNavigator();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Dashboard"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}
    >
      <BottomTab.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={({ navigation }) => ({
          title: "Dashboard",
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons
              name="bookshelf"
              size={25}
              color={color}
            />
          ),
          headerRight: () => (
            <View
              style={{
                marginRight: 15,
              }}
            >
              <Button
                onPress={() => navigation.navigate("About")}
                size="tiny"
                status="primary"
              >
                How it works
              </Button>
            </View>
          ),
          headerTitle: () => {
            return (
              <Image
                source={require("../assets/images/header.png")}
                style={{ width: 80, height: 35 }}
                resizeMode="contain"
              />
            );
          },
        })}
      />
      <BottomTab.Screen
        name="Servers"
        component={ServersScreen}
        options={{
          title: "Servers",
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons
              name="access-point"
              size={25}
              color={color}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="MyAccount"
        component={MyAccountScreen}
        options={{
          title: "My Account",
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons
              name="book-account"
              size={25}
              color={color}
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}