import { useNavigation, useRoute } from "@react-navigation/core";
import {
  Card,
  Layout,
  Text,
  Divider,
  Button,
  Avatar,
  useTheme,
  Spinner,
} from "@ui-kitten/components";
import React, { useEffect, useMemo, useState } from "react";
import { View, ScrollView } from "react-native";

import formatDistance from "date-fns/formatDistance";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Dimensions } from "react-native";
const screenWidth = Dimensions.get("window").width;
import supabase from "../supabase";
import { Tab, TabBar } from "@ui-kitten/components";
import { LineChart } from "react-native-chart-kit";
import StyleSheet from "react-native-media-query";

export default function ServerScreen() {
  const {
    params: { server: serverName },
  } = useRoute();

  const navigation = useNavigation();
  const [server, setServer] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [transaction, setTransaction] = useState();

  const initServer = async () => {
    if (!serverName) {
      return;
    }
    let { data: freshServers, error } = await supabase
      .from("servers")
      .select("*, backers (blocks, created_at, users ( username )), trades (*)")
      .eq("name", serverName)
      .limit(1)
      .single();

    if (freshServers) setServer(freshServers);
  };

  const initCurrencies = async () => {
    if (!serverName || !server) {
      return;
    }
    const response = await fetch("/api/currency/");

    const prices = await response.json();

    if (prices["BTC"]) {
      setCurrencies(prices);
    }
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    initServer();
  }, []);

  useEffect(() => {
    initCurrencies();
  }, [server]);

  useEffect(() => {
    // supabase.from("*")
    //   .on("INSERT", (payload) => {
    //     if (payload.new) {
    //       setTimeline(timeline => [payload.new, ...timeline])
    //     }
    //   })
    //   .subscribe();
  }, []);

  if (!server || !currencies[server.currency]) {
    return (
      <Layout
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Spinner size="giant" />
      </Layout>
    );
  }

  return (
    <Layout style={{ flex: 1 }} level="3">
      <View style={styles.contain} dataSet={{ media: ids.contain }}>
        <ScrollView stickyHeaderIndices={[2]}>
          <View style={{ bottom: -30, zIndex: 1000 }}>
            <Layout style={{ height: 120 }} level="3" />
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                width: "100%",
                // bottom: -30,
              }}
            >
              <Layout
                style={{ padding: 5, borderRadius: 10000, zIndex: 10000 }}
              >
                <Avatar
                  size="giant"
                  source={{
                    uri:
                      currencies[server.currency]["logo_url"] ??
                      "/ii/crypto.png",
                  }}
                  resizeMode="contain"
                  style={{ zIndex: 1 }}
                />
              </Layout>
            </View>
          </View>
          <Layout style={{ 
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          }}>
            <Layout
              style={{
                marginBottom: 10,
                marginTop: 60,
                alignItems: "center",
                justifyContent: "center",
              }}
              key={server.id}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text category="label">{server.name}</Text>
              </View>
              <View style={{ height: 10 }} />
              <Divider />
              <View style={{ height: 10 }} />
              <Text category="p1" status="success">
                <MaterialCommunityIcons name="trending-up" size={15} />{" "}
                {server.trades
                  .reduce((p, c) => p + Number(c.profit), 0)
                  .toFixed(6)}{" "}
                %{" "}
                <Text status="control">
                  <MaterialCommunityIcons
                    name="chevron-double-right"
                    size={15}
                  />
                </Text>{" "}
                {server.trades
                  .reduce((p, c) => p + Number(c.profit), 0)
                  .toFixed(6) /
                  100 +
                  1}{" "}
                <Text status="control" category="p2">
                  {server.currency}
                </Text>
              </Text>
              <View style={{ height: 5 }} />
              <Text category="c2">{server.backers.length} Backers</Text>
              <View style={{ height: 5 }} />
              <Text category="s2">
                Backed with {server.bought} {server.currency}
              </Text>
              {server.bought < 1 && (
                <View style={{ marginTop: 20 }}>
                  <Button
                    size="small"
                    style={{ borderRadius: 1000 }}
                    onPress={() =>
                      navigation.navigate("JoinServer", { server: serverName })
                    }
                  >
                    Join Server
                  </Button>
                </View>
              )}
            </Layout>
          </Layout>
          <Layout>
            <Layout style={{ paddingHorizontal: 10 }}>
              <TabBar
                selectedIndex={selectedIndex}
                onSelect={(index) => setSelectedIndex(index)}
              >
                <Tab title="Trades" />
                <Tab title="Backers" />
              </TabBar>
            </Layout>
          </Layout>

          <Layout>
            <Layout style={{ paddingHorizontal: 10 }}>
              {(() => {
                switch (selectedIndex) {
                  case 0:
                    return <Trades server={server} currencies={currencies} />;
                  case 1:
                    return <Backers server={server} />;
                  default:
                    break;
                }
              })()}
            </Layout>
          </Layout>
        </ScrollView>
      </View>
    </Layout>
  );
}

function Backers({ server }) {
  if (server && server.backers < 1) {
    return (
      <Layout style={{ minHeight: 500 }}>
        <Layout
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <Text style={{ textAlign: "center" }} category="h6">
            {`No Backers On This Server\n \nBe The First To Back This Server`}
          </Text>
        </Layout>
      </Layout>
    );
  }

  return (
    <View style={{ paddingVertical: 10, minHeight: 500 }}>
      {server.backers
        .sort((cc, ii) => ii.blocks - cc.blocks)
        .map((backer) => (
          <Card key={backer.created_at} style={{ marginBottom: 10 }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Avatar
                size="small"
                source={{
                  uri: `https://avatars.dicebear.com/api/male/${backer.users.username}.svg?background=%230000ff`,
                }}
              />
              <View style={{ flex: 1, marginLeft: 15 }}>
                <Text category="label">{backer.users.username}</Text>
                <Text category="s2" appearance="hint">
                  {formatDistance(new Date(backer.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </Text>
              </View>
              <View style={{ alignItems: "flex-end" }}>
                <Text category="h6" status="success">
                  {backer.blocks} {server.currency}
                </Text>
                {server.total_profit && (
                  <Text category="c2" status="success">
                    {((server.total_profit * backer.blocks) / 100).toFixed(6)}{" "}
                    {server.currency}{" "}
                    <Text category="c2" appearance="hint">
                      Profit
                    </Text>
                  </Text>
                )}
              </View>
            </View>
          </Card>
        ))}
    </View>
  );
}

function Trades({ server, currencies }) {
  const the = useTheme();
  if (server && server.bought < 1) {
    return (
      <Layout style={{ minHeight: 500 }}>
        <Layout
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <Text style={{ textAlign: "center" }} category="h6">
            {`No Trades On This Server\n \nServer Is Not Fully Backed Yet`}
          </Text>
        </Layout>
      </Layout>
    );
  }
  if (!currencies["BTC"]) {
    return <Layout />;
  }

  if (server && server.trades.length < 1) {
    return (
      <Layout style={{ minHeight: 500 }}>
        <Layout
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <Text style={{ textAlign: "center" }} category="h6">
            {`Preparing Server`}
          </Text>
        </Layout>
      </Layout>
    );
  }

  return (
    <View style={{ paddingVertical: 10, minHeight: 500 }}>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <LineChart
          data={{
            labels: ["", "", "", "", "", "", "", "", "", ""],
            datasets: [
              {
                data: [
                  ...server.trades
                    .reverse()
                    .slice(Math.max(server.trades.reverse().length - 10, 0))
                    .map((oo) => oo.profit),
                ],
              },
            ],
          }}
          width={Dimensions.get("window").width - 50} // from react-native
          height={220}
          yAxisSuffix="%"
          yAxisInterval={1} // optional, defaults to 1
          chartConfig={{
            backgroundColor: the["background-basic-color-1"],
            backgroundGradientFrom: the["background-basic-color-1"],
            backgroundGradientTo: the["background-basic-color-1"],
            decimalPlaces: 4, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            style: {
              borderRadius: 16,
            },
            propsForDots: {
              r: "4",
              strokeWidth: "2",
              stroke: "#ffa726",
            },
          }}
          bezier
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </View>
      {server.trades.reverse().map((trade) => {
        const current = currencies[trade.currency];
        const previous = currencies[trade.previous_currency];
        return (
          <Card key={trade.created_at} style={{ marginBottom: 10 }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                size="small"
                source={{
                  uri: previous?.logo_url ?? "/ii/crypto.png",
                }}
                resizeMode="contain"
              />
              <View style={{ marginHorizontal: 15 }}>
                <MaterialCommunityIcons
                  name="arrow-right-bold"
                  size={24}
                  color="white"
                />
              </View>
              <Avatar
                size="small"
                source={{
                  uri: current?.logo_url ?? "/ii/crypto.png",
                }}
              />
            </View>
            <View style={{ height: 10 }} />
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ textAlign: "center" }}>{trade.message}</Text>
              <View style={{ height: 5 }} />
              <Text category="label">
                Profit: <Text status="success">{trade.profit}</Text>%
              </Text>
            </View>
          </Card>
        );
      })}
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  contain: {
    flex: 1,
    maxWidth: "100%",
    "@media (min-width: 768px)": {
      width: 700,
      marginHorizontal: "auto",
    },
  },
});
