import { useNavigation } from "@react-navigation/core";
import {
  Card,
  Layout,
  Text,
  Divider,
  Button,
  Avatar,
  Spinner,
  Input,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { MyServer, NewServer } from "../components/SingleServer";
import supabase from "../supabase";
import StyleSheet from "react-native-media-query";

const logos = {
  BTC: require("../assets/logos.crypto/BTC.png"),
  ETH: require("../assets/logos.crypto/ETH.png"),
  BCH: require("../assets/logos.crypto/BCH.png"),
  BNB: require("../assets/logos.crypto/BNB.png"),
};

export default function ServersScreen() {
  const navigation = useNavigation();
  const [servers, setServers] = useState([]);
  const [searchedServers, setSearchedServers] = useState([]);
  const [searchingServers, setSearchingServers] = useState(false);
  const [query, setQuery] = useState("");

  const initServers = async () => {
    let { data: freshServers, error } = await supabase
      .from("servers")
      .select("*, backers (blocks, id)")
      .lte("bought", 0.99999)
      .order("created_at");

    if (freshServers) {
      setServers(freshServers);
    }
  };

  useEffect(() => {
    initServers();
  }, []);

  const searchServers = async () => {
    if (query.length <= 2) {
      return;
    }

    setSearchingServers(true);

    let { data: freshServers, error } = await supabase
      .from("servers")
      .select("*, backers (blocks, id)")
      .like("name", "%" + query.toLocaleUpperCase() + "%")
      .limit(10)
      .order("created_at");

    setSearchingServers(false);

    if (freshServers) {
      setSearchedServers(freshServers);
    }
  };

  useEffect(() => {
    searchServers();
  }, [query]);

  return (
    <Layout style={{ flex: 1 }}>
      <View style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
        <Input
          placeholder="Search server name"
          value={query}
          onChangeText={setQuery}
        />
      </View>
      <ListServers navigation={navigation} servers={query.length >= 3 ? searchedServers :  servers} title={query.length ? "Servers matching :" + query : "New Servers"} />
    </Layout>
  );
}

function ListServers({ servers = [], title = "", navigation }) {
  if (title == "") {
    return <View />;
  }
  return (
    <ScrollView>
      <View style={{ paddingHorizontal: 10 }}>
        <View style={{ height: 20 }} />
        <View style={{}}>
          <Text category="h6">{title}</Text>
          <Text category="label">You can explore and join any open server below</Text>
          <View style={{ height: 10 }} />

          {servers.length == 0 && <Spinner size="giant" />}
        </View>
      </View>
      <View style={{ flexDirection: "row", flexWrap: "wrap", marginLeft: 10 }}>
        {servers.map((server) => (
          <View
            key={server.id}
            style={styles.containServer}
            dataSet={{ media: ids.containServer }}
          >
            <NewServer navigation={navigation} server={server} />
          </View>
        ))}
      </View>
    </ScrollView>
  );
}

const { ids, styles } = StyleSheet.create({
  containServer: {
    paddingRight: 10,
    width: "100%",
    "@media (min-width: 768px)": {
      width: "50%",
    },
    "@media (min-width: 992px)": {
      width: "33.333333%",
    },
  },
});
