import { Button, Card, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { useMemo, useState } from "react";
import supabase from "../supabase";
import { NewServer } from "./SingleServer";

import { Modal, View } from "react-native";

const statuses = {
  "joined-cloud": "success",
  "new-server": "warning",
  trade: "info",
  backed: "danger",
};

export default function TimelineItem({ line, navigation }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [servers, setServers] = useState([]);

  const serversName = useMemo(() => {
    if (line.type == "trade") {
      return line.message
        .match(new RegExp("server: " + "(.*)" + " >"))[1]
        .split(" ")
        .filter((e) => e);
    }
    if (line.type == "backed") {
      return line.message
        .match(new RegExp("backed " + "(.*)" + " with"))[1]
        .split(" ")
        .filter((e) => e);
    }
    if (line.type == "new-server") {
      return line.message
        .match(new RegExp("server (" + ".*" + ") ini"))[1]
        .replace("(", "")
        .replace(")", "")
        .split(" ")
        .filter((e) => e);
    }

    return null;
  }, []);

  const fetchServers = async () => {
    setLoading(true);
    let { data: freshServers, error } = await supabase
      .from("servers")
      .select("*, backers (blocks, id)")
      .order("created_at", { ascending: false })
      .in("name", serversName);

    setLoading(false);

    if (freshServers) {
      setServers(freshServers);
    }
  };

  const onNavigate = () => setVisible(false)

  return (
    <>
      <Card
        onPress={() => {
          if (!serversName) {
            return;
          }
          setVisible(true);
          if (servers.length == 0) {
            fetchServers();
          }
        }}
        style={{ marginBottom: 10 }}
        status={statuses[line.type] ?? "control"}
        key={line.id}
      >
        <View style={{ alignItems: "center", justifyContent: "center"}}>
          <Text style={{ textAlign: "center"}}>{line.message}</Text>
        </View>
      </Card>

      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        style={{ flex: 1 }}
        onRequestClose={() => setVisible(false)}
      >
        <Layout
          style={{
            backgroundColor: servers.length >= 1 ? "transparent" : undefined,
            flex: 1,
            paddingTop: 50,
          }}
        >
          {loading && (
            <Layout
              style={{
                backgroundColor: "transparent",
                alignItems: "center",
                justifyContent: "center",
                paddingVertical: 30,
                width: "100%",
              }}
            >
              <Spinner size="large" />
            </Layout>
          )}
          {servers.length >= 1 && (
            <View
              style={{
                paddingHorizontal: 15,
                paddingTop: 50,
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1
              }}
            >
              {servers.map((server) => (
                <NewServer server={server} navigation={navigation} onNavigate={onNavigate} />
              ))}
              <View style={{ justifyContent: "center", alignItems: "center"}}>
                  <Button status="danger" onPress={onNavigate} style={{ borderRadius: 1000 }} size="small">CLOSE</Button>
            </View>
            </View>
          )}
        </Layout>
      </Modal>
    </>
  );
}
