import { Avatar, Button, Card, Divider, Text } from "@ui-kitten/components";
import React from "react";
import { View, Dimensions } from "react-native";

const logos = {
  BTC: require("../assets/logos.crypto/BTC.png"),
  ETH: require("../assets/logos.crypto/ETH.png"),
  BCH: require("../assets/logos.crypto/BCH.png"),
  BNB: require("../assets/logos.crypto/BNB.png"),
};

export function NewServer({ server, navigation, onNavigate }) {
  return (
    <Card style={{ marginBottom: 10 }} key={server.id}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Avatar source={logos[server.currency]} size="tiny" />
          <View style={{ marginLeft: 10 }}>
            <Text category="label">{server.name}</Text>
            <Text category="label" appearance="hint">
              {server.currency} server
            </Text>
          </View>
        </View>
        <Button
          // status="success"
          size="tiny"
          onPress={() => {
            if (onNavigate) {
              onNavigate();
            }
            navigation.navigate("Server", { server: server.name })
          }}
        >
          View server
        </Button>
      </View>
      <View style={{ height: 10 }} />
      <Divider />
      <View style={{ height: 10 }} />
      <Text category="c2">{server.backers.length} Backers</Text>
      <View style={{ height: 5 }} />
      <Text category="s2">
        Backed with {server.bought} {server.currency}
      </Text>
    </Card>
  );
}

export function MyServer({ server, navigation }) {
  return (
    <Card
      style={{
        marginRight: 20,
        width: Dimensions.get("window").width * 0.7,
        marginBottom: 10,
      }}
      key={server.id}
      onPress={() => navigation.navigate("Server", { server: server.name })}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View>
          <Text category="label">{server.name}</Text>
          {server.profit ? (
            <Text category="s1">
              Profit :{" "}
              <Text category="s2" status="success">
                {server.profit}%
              </Text>
            </Text>
          ) : (
            <Text category="s1">No Trades Yet</Text>
          )}
        </View>
        <Avatar source={logos[server.currency]} size="medium" />
      </View>
      <View style={{ height: 30 }} />
      <Divider />
      <View style={{ height: 10 }} />
      <Text category="s2">
        Backed with {server.bought} {server.currency}
      </Text>
    </Card>
  );
}
