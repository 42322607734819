import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import { Card, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import HomeStats from "../components/HomeStats";
import TimelineItem from "../components/TimelineItem";
import StyleSheet from 'react-native-media-query';

import supabase from "../supabase";

export default function DashboardScreen() {
  const [timeline, setTimeline] = useState([]);
  const [stats, setStats] = useState({});
  const navigation = useNavigation();

  const initTimeLine = async () => {
    let { data: freshTimeline, error } = await supabase
      .from("timeline")
      .select()
      .order("created_at", { ascending: false })
      .limit(100);

    if (freshTimeline) {
      setTimeline(freshTimeline);
    }
  };

  const fetDash = async () => {
    const response = await fetch("/api/dashboard");
    if (response.status == 200) {
      const data = await response.json();
      setStats(data);
    }
  };

  useEffect(() => {
    initTimeLine();
    (async () => {
      const uu = await supabase.auth.session();

      await fetch("/api/user", {
        method: "POST",
        headers: {
          "x-app-api": uu?.access_token,
        },
      });
    })();
    (async () => {
      // const { data: server, error: serverError } = await supabase
      //   .rpc("get_full_server_with_user");
        
      // console.log(server);
    })();
    fetDash();
  }, []);

  useEffect(() => {
    supabase
      .from("*")
      .on("INSERT", (payload) => {
        if (payload.new) {
          setTimeline((timeline) => [payload.new, ...timeline]);
        }
      })
      .subscribe();
  }, []);

  return (
    <Layout style={{ flex: 1 }}>
      <ScrollView>
        <HomeStats stats={stats} />
        <View style={{ height: 20 }} />
        <View style={{ paddingHorizontal: 10 }}>
          {timeline.length == 0 && (
            <Layout
              style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
            >
              <Spinner size="giant" />
            </Layout>
          )}
          <View style={{ }}>
            <Text category="h6">Recent Updates</Text>
            <View style={{ height: 10 }} />
          </View>
          {timeline.map((line) => (
            <TimelineItem line={line} key={line.id} navigation={navigation} />
          ))}
        </View>
      </ScrollView>
    </Layout>
  );
}

const {ids, styles} = StyleSheet.create({
  contain: {
      flex: 1,
      maxWidth: '100%',
      '@media (min-width: 768px)': {
          width: 700,
          marginHorizontal: "auto",
      },
  }
})