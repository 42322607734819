import { useRoute } from "@react-navigation/core";
import {
  Button,
  Text,
  Divider,
  Layout,
  Spinner,
  Input,
} from "@ui-kitten/components";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { View, ScrollView } from "react-native";
import QRCode from "react-qr-code";
import StyleSheet from "react-native-media-query";

import { CopyToClipboard } from "react-copy-to-clipboard";
import supabase from "../supabase";

const points = [
  "You will be added to another server if your preferred server is fully backed when your transaction confirms, so use the best transaction fees.",
  "Provide correct address for network, this is where your crypto and trading profits are sent to when server is retired.",
  "Servers are retired if 70% of backers in a server vote to close the server.",
];

const buyable = {
  BTC: [0.01, 0.02, 0.05, 0.1, 0.2, 0.5],
  BCH: [0.5, 1],
  BNB: [0.5, 1],
  ETH: [0.25, 0.5, 0.75, 1],
};

export default function JoinServerScreen() {
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState("");
  const [memoTag, setMemoTag] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {}, []);

  const {
    params: { server: serverName },
  } = useRoute();

  const [server, setServer] = useState();

  const initServer = async () => {
    if (!serverName) {
      return;
    }
    let { data: freshServers, error } = await supabase
      .from("servers")
      .select("*, backers (blocks, created_at, users ( username )), trades (*)")
      .eq("name", serverName)
      .limit(1)
      .single();

    if (freshServers) setServer(freshServers);

    const uu = await supabase.auth.session();

    const response = await fetch(
      "/api/transaction?server=" + freshServers.name,
      {
        headers: {
          "Content-Type": "application/json",
          "x-app-api": uu?.access_token,
        },
      }
    );

    const { transaction: tnx } = await response.json();

    setTransaction(tnx);
    setInitialLoading(false);
  };

  useEffect(() => {
    initServer();
  }, []);

  if (initialLoading || !server) {
    return (
      <Layout
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <Spinner size="giant" />
      </Layout>
    );
  }

  const initTransaction = async () => {
    if (loading) return;
    if (!amount) {
      return alert("Please select an amount");
    }
    if (!address) {
      return alert("Please enter a valid address");
    }
    if (server.currency == "BNB" && !memoTag) {
      return alert("Please enter a valid BNB Memotag");
    }

    setLoading(true);

    const uu = await supabase.auth.session();

    const response = await fetch("/api/servers", {
      method: "POST",
      body: JSON.stringify({
        currency: server.currency,
        server: server.name,
        amount,
        address: address + (server.currency == "BNB" && ":" + memoTag),
      }),
      headers: {
        "Content-Type": "application/json",
        "x-app-api": uu?.access_token,
      },
    });

    setLoading(false);

    if (response.status != 200) {
      return;
    }

    const { transaction: tnx } = await response.json();

    setTransaction(tnx);
  };

  if (transaction) {
    return (
      <Layout style={{ flex: 1 }}>
        <ScrollView style={{ flex: 1 }}>
          <Layout style={{}}>
            <View style={{ height: 30, backgroundColor: "transparent" }} />
            <Layout style={{ alignItems: "center", justifyContent: "center" }}>
              <Text>Copy the address, or scan the QR code below</Text>
              <View style={{ height: 25, backgroundColor: "transparent" }} />
              <Text category="c2">{transaction.address}</Text>
              {transaction?.memo && (
                <>
                  <View style={{ height: 5, backgroundColor: "transparent" }} />
                  <CopyToClipboard
                    text={transaction.memo}
                    onCopy={() =>
                      alert(`${server.currency} memo/destination tag copied`)
                    }
                  >
                    <Text category="p2">MemoTag: {transaction.memo}</Text>
                  </CopyToClipboard>
                </>
              )}
              <View style={{ height: 20, backgroundColor: "transparent" }} />

              <CopyToClipboard
                text={transaction.address}
                onCopy={() => alert(`${server.currency} address copied`)}
              >
                <MaterialCommunityIcons
                  name="content-copy"
                  size={24}
                  color="white"
                />
              </CopyToClipboard>

              <View style={{ height: 20, backgroundColor: "transparent" }} />
            </Layout>
            <Divider />

            <View style={{ height: 20, backgroundColor: "transparent" }} />

            <Layout style={{ alignItems: "center", justifyContent: "center" }}>
              <View style={{ padding: 10, backgroundColor: "white" }}>
                <QRCode value={transaction.address} />
              </View>
            </Layout>

            <Layout
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginVertical: 25,
              }}
            >
              <Text category="label" appearance="hint">
                AMOUNT
              </Text>
              <Text category="h5">{`${transaction.amount} ${transaction.currency}`}</Text>
            </Layout>

            <Layout
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
                marginTop: 40,
              }}
            >
              <Text
                style={{ textAlign: "center" }}
                status="danger"
                category="c2"
              >
                Send only <Text category="s2">{transaction.currency}</Text> to
                this address
                {transaction?.memo && ", specify MemoTag provided"}. Sending any
                other asset or imcomplete deposit may result in the loss of your
                funds.
              </Text>
            </Layout>
          </Layout>
        </ScrollView>
      </Layout>
    );
  }

  return (
    <Layout style={{ flex: 1 }} level="3">
      <View style={styles.contain} dataSet={{ media: ids.contain }}>
        <Layout style={{ 
            flex: 1,
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            marginTop: 15,
            overflow: "hidden"
        }}>
        <ScrollView style={{ flex: 1 }}>
          <Layout
            style={{
              paddingHorizontal: 10,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
              paddingTop: 15,
            }}
          >
            <Text category="label">NOTE BEFORE YOU PROCEED</Text>
            <View style={{ height: 15 }} />
            {points.map((inst) => (
              <>
                <Text
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    textAlignVertical: "center",
                  }}
                  category="s2"
                >
                  <MaterialCommunityIcons
                    name="checkbox-blank-circle-outline"
                    size={18}
                    color="white"
                  />
                  {"  "}
                  {inst}
                </Text>
                <View style={{ height: 8 }} />
              </>
            ))}
          </Layout>
          <Divider />
          <Layout
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              paddingHorizontal: 50,
              marginTop: 20,
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text category="label" appearance="hint">
                Select Amount
              </Text>
            </View>
            {buyable[server.currency]
              .filter((a) => a <= server.total_buyable - server.bought)
              .map((value) => (
                <Button
                  key={value}
                  appearance={amount == value ? "outline" : "filled"}
                  children={`${value} ${server.currency}`}
                  onPress={() => setAmount(value)}
                  style={{
                    marginHorizontal: 5,
                    borderRadius: 10000,
                    marginBottom: 10,
                  }}
                  size="small"
                />
              ))}
          </Layout>

          <View style={{ height: 20 }} />

          <Layout style={{ paddingHorizontal: 10 }}>
            <Input
              placeholder="Wallet Address"
              label="Wallet Address"
              onChangeText={setAddress}
              caption={
                <View>
                  <Text
                    category="label"
                    status="warning"
                    style={{ marginTop: 8 }}
                  >
                    Address where your crypto will be deposited after server is
                    closed
                  </Text>
                </View>
              }
            />
            {server.currency == "BNB" && (
              <>
                <View style={{ height: 15 }} />
                <Input
                  placeholder="Memotag"
                  label="BNB Memotag"
                  onChangeText={setMemoTag}
                />
              </>
            )}
          </Layout>
          <Layout
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 50,
              marginTop: 30,
            }}
          >
            {loading ? (
              <Button status="warning" size="small">
                Processing ...
              </Button>
            ) : (
              <Button status="success" onPress={initTransaction} size="small">
                Continue
              </Button>
            )}
          </Layout>
        </ScrollView>
        </Layout>
      </View>
    </Layout>
  );
}

const { ids, styles } = StyleSheet.create({
  contain: {
    flex: 1,
    maxWidth: "100%",
    "@media (min-width: 768px)": {
      width: 700,
      marginHorizontal: "auto",
    },
  },
});
