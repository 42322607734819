import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Layout, Spinner, Text } from "@ui-kitten/components";
import React from "react";
import { View, Dimensions } from "react-native";
import StyleSheet from "react-native-media-query";

export default function HomeStats({ stats = {} }) {
  return (
    <View
      style={{
        flexWrap: "wrap",
        flexDirection: "row",
        marginTop: 10,
        marginLeft: 10,
      }}
    >
      <View dataSet={{ media: ids.containServer }} style={styles.containServer}>
        <Layout
          style={{
            width: "100%",
            padding: 15,
            borderRadius: 5,
          }}
          level="3"
        >
          <Text category="label" appearance="hint">
            Backers / Users
          </Text>
          <View style={{ height: 5 }} />
          {!stats.users ? (
            <Spinner size="tiny" />
          ) : (
            <Text category="s1">
              {stats.backers} /{" "}
              <Text category="c2" appearance="hint">
                {" "}
                {stats.users}
              </Text>
            </Text>
          )}

          <Layout
            style={{
              height: 4,
              width: "100%",
              borderRadius: 1000,
              marginTop: 10,
              overflow: "hidden",
            }}
            level="2"
          >
            <View
              style={{
                height: 4,
                width: `${
                  !stats.users ? 0 : (stats.backers / stats.users) * 100
                }%`,
                backgroundColor: "#F28F3B",
              }}
            />
          </Layout>
        </Layout>
      </View>
      <View dataSet={{ media: ids.containServer }} style={styles.containServer}>
        <Layout
          style={{
            width: "100%",
            padding: 15,
            borderRadius: 5,
          }}
          level="3"
        >
          <Text category="label" appearance="hint">
            Running Servers
          </Text>
          <View style={{ height: 5 }} />
          <Text category="s1">
            {!stats.servers ? (
              <Spinner size="tiny" />
            ) : (
              <Text category="s1">{stats.servers}</Text>
            )}
          </Text>
          <Layout
            style={{
              height: 4,
              width: "100%",
              borderRadius: 1000,
              marginTop: 10,
              overflow: "hidden",
            }}
            level="2"
          >
            <View
              style={{
                height: 4,
                width: `${!stats.servers ? 0 : 81}%`,
                backgroundColor: "#d94c92",
              }}
            />
          </Layout>
        </Layout>
      </View>
      <View dataSet={{ media: ids.containServer }} style={styles.containServer}>
        <Layout
          style={{
            width: "100%",
            padding: 15,
            borderRadius: 5,
          }}
          level="3"
        >
          <Text category="label" appearance="hint">
            Profit Growth
          </Text>
          <View style={{ height: 5 }} />
          <Text category="s1">
            {!stats.profit ? (
              <Spinner size="tiny" />
            ) : (
              <Text category="s1" status="success">
                <MaterialCommunityIcons name="trending-up" size={20} />{" "}
                {stats.profit.toFixed(4)}{" "}
                <Text category="c2" status="success">
                  %
                </Text>
              </Text>
            )}
          </Text>

          <Layout
            style={{
              height: 4,
              width: "100%",
              borderRadius: 1000,
              marginTop: 10,
              overflow: "hidden",
            }}
            level="2"
          >
            <View
              style={{
                height: 4,
                width: `${!stats.profit ? 0 : 100}%`,
                backgroundColor: "#97c728",
              }}
            />
          </Layout>
        </Layout>
      </View>
      <View dataSet={{ media: ids.containServer }} style={styles.containServer}>
        <Layout
          style={{
            width: "100%",
            padding: 15,
            borderRadius: 5,
          }}
          level="3"
        >
          <Text category="label" appearance="hint">
            Avg. Uptime
          </Text>
          <View style={{ height: 5 }} />

          {!stats.profit ? (
            <Spinner size="tiny" />
          ) : (
            <Text category="s1">
              99<Text category="c2">% Uptime</Text>
            </Text>
          )}

          <Layout
            style={{
              height: 4,
              width: "100%",
              borderRadius: 1000,
              marginTop: 10,
              overflow: "hidden",
            }}
            level="2"
          >
            <View
              style={{
                height: 4,
                width: `${!stats.profit ? 0 : 98}%`,
                backgroundColor: "#8c898b",
              }}
            />
          </Layout>
        </Layout>
      </View>
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  containServer: {
    marginBottom: 10,
    paddingRight: 10,
    width: "100%",
    "@media (max-width: 768px)": {
      width: "50%",
    },
    "@media (min-width: 769px)": {
      width: "25%",
    },
  },
});
