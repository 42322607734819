/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const guestLinking = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Guest: {
        screens: {
          IntroScreen: '/',
          LoginScreen: '/login',
          NotFound: '*',
        }
      }
    },
  },
};


const authLinking = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      LoginScreen: '/',
      Root: {
        screens: {
          Dashboard: "/dashboard",
          Servers: "/servers",
          MyAccount: "/account",
        },
      },
      Server: '/server/:server',
      JoinServer: '/join/:server',
      About: "/about",
      NotFound: '*',
    },
  },
};

export {
  guestLinking, authLinking
};
