import * as React from "react";
import {
  StyleSheet,
  Text,
  Pressable,
  View,
  useWindowDimensions,
} from "react-native";
import {
  createNavigatorFactory,
  NavigationHelpersContext,
  useNavigationBuilder,
  TabRouter,
  TabActions,
} from "@react-navigation/native";
import {
  Drawer,
  DrawerItem,
  IndexPath,
  Layout,
  BottomNavigation,
  BottomNavigationTab,
} from "@ui-kitten/components";

function TabNavigator({
  initialRouteName,
  children,
  screenOptions,
  tabBarStyle,
  contentStyle,
}) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  const { width } = useWindowDimensions();

  if (width > 768) {
    return (
      <NavigationContent>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
          }}
        >
          <Layout style={[{ flexDirection: "row" }, { width: 300, borderRightWidth: 1 }]}>
            <Drawer
              selectedIndex={
                new IndexPath(
                  state.routes.findIndex(
                    (route) => route.key == state.history.at(-1)?.key
                  )
                )
              }
              onSelect={(index) => {
                const route = state.routes.at(index.row);

                if (!route) {
                  return;
                }
                const event = navigation.emit({
                  type: "tabPress",
                  target: route.key,
                  canPreventDefault: true,
                });

                if (!event.defaultPrevented) {
                  navigation.dispatch({
                    ...TabActions.jumpTo(route.name),
                    target: state.key,
                  });
                }
              }}
            >
              {state.routes.map((route) => (
                <DrawerItem
                  title={descriptors[route.key].options.title || route.name}
                />
              ))}
            </Drawer>
          </Layout>

          <View style={[{ flex: 1 }, contentStyle]}>
            {state.routes.map((route, i) => {
              return (
                <View
                  key={route.key}
                  style={[
                    StyleSheet.absoluteFill,
                    { display: i === state.index ? "flex" : "none" },
                  ]}
                >
                  {descriptors[route.key].render()}
                </View>
              );
            })}
          </View>
        </View>
      </NavigationContent>
    );
  }

  return (
    <NavigationContent>
      <View style={[{ flex: 1 }, contentStyle]}>
        {state.routes.map((route, i) => {
          return (
            <View
              key={route.key}
              style={[
                StyleSheet.absoluteFill,
                { display: i === state.index ? "flex" : "none" },
              ]}
            >
              {descriptors[route.key].render()}
            </View>
          );
        })}
      </View>
      <Layout style={[tabBarStyle]}>
        <BottomNavigation
          selectedIndex={
            (
              state.routes.findIndex(
                (route) => route.key == state.history.at(-1)?.key
              )
            )
          }
          onSelect={(index) => {
            const route = state.routes.at(index);

            if (!route) {
              return;
            }
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
              canPreventDefault: true,
            });

            if (!event.defaultPrevented) {
              navigation.dispatch({
                ...TabActions.jumpTo(route.name),
                target: state.key,
              });
            }
          }}
        >
          {state.routes.map((route) => (
            <BottomNavigationTab
              title={descriptors[route.key].options.title || route.name}
            />
          ))}
        </BottomNavigation>
      </Layout>
    </NavigationContent>
  );
}

export const createMainTabNavigator = createNavigatorFactory(TabNavigator);
