import { Layout, Text } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import * as React from "react";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Touchable,
  TouchableOpacity,
  View,
} from "react-native";

export default function AboutScreen() {
  return (
    <Layout style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingVertical: 10, paddingHorizontal: 15 }}
      >
        <Text category="h6">What is BitX CLOUD?</Text>
        <View style={{ height: 10 }} />
        <Text category="p1">
          BitX CLOUD is a public automatic cryptocurrency trading platform
          managed by the{" "}
          <TouchableOpacity
            onPress={() => WebBrowser.openBrowserAsync("https://bitx.hr")}
          >
            <Text status="primary">BitX TEAM</Text>
          </TouchableOpacity>
          .{`\n\n`}The project originates from the{" "}
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">Binance </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">Trading </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">Bot </Text>
          </TouchableOpacity>
          on github. After intense experimentations and changes on the project
          we decided to make Binance Trading Bot into business to give easy
          access and setup of bots to make maximum profit for interested users.
        </Text>
        <View style={{ height: 25 }} />
        <Text category="h6">How it works?</Text>
        <View style={{ height: 10 }} />
        <Text category="p1">
          BitX CLOUD runs instances of our improved
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary"> Binance </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">Trading </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">Bot </Text>
          </TouchableOpacity>
          on servers backed by hundreds users.{`\n`}Once a server is fully
          backed, scouting and trading begins automatically.{`\n\n`}Binance
          Trade Bot makes profit by listening for price changes between multiple
          cryptocurrencies on Binance market using BUSD or USDT as base
          currencies and making a BUY or SELL if any potential profit is made
          based on price change. We took this further by developing our own
          internal strategy that maximizes profit on every bot trade made.{`\n\n`}Note: Binance Trading Bot makes profit on cryptocurrences only.
        </Text>
        <View style={{ marginVertical: 10 }}>
          <TouchableOpacity
            onPress={() =>
              WebBrowser.openBrowserAsync(
                "https://github.com/edeng23/binance-trade-bot"
              )
            }
          >
            <Text status="primary">
              Learn more about BINANCE TRADE BOT
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ height: 25 }} />
        <Text category="h6">DISCLAIMER / NOTICE</Text>
        <View style={{ height: 10 }} />
        <Text category="p1">
            Trading cryptocurrencies carries a high level of risk, and may not be suitable for all investors. Before deciding to trade cryptocurrency you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with cryptocurrency trading, and seek advice from an independent financial advisor. ICO's, IEO's, STO's and any other form of offering will not guarantee a return on your investment.
        </Text>
        <View style={{ height: 25 }} />
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
