import React, { useEffect, useState } from "react";
import { Image, View } from "react-native";
import { Layout, useTheme, Text, Input, Button } from "@ui-kitten/components";
import supabase from "../supabase";
import Chance from "chance";
import StyleSheet from 'react-native-media-query';

const mnemonicWords = require('mnemonic-words');

const chance = new Chance();

const LoginScreen = () => {
  const [userExists, setUserExists] = useState({
    mnemonic: null,
    hasUser: false,
    hasLoaded: false,
  });

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const attemptToLogin = async () => {
    const { user, session, error } = await supabase.auth.signIn({
        email: `${username}@bitx.cloud`,
        password: password.replace(/\s/g,'').toLowerCase(),
    });

    if (error) {
        alert("Incorrect Nnemonic phrase, Please check and try again")
    }
  };



  const attemptToRegister = async () => {
    await supabase.auth.signUp({
        email: `${username}@bitx.cloud`,
        password: userExists.mnemonic.join("").toLowerCase(),
      });
  };

  const checkIfUserExists = async () => {
    if (!username || username == "" || !username.match(/^[0-9a-zA-z]+$/)) {
        alert("Please enter a valid username")
      return;
    }

    const { count } = await supabase
      .from("users")
      .select("username", { count: "exact", head: true })
      .eq("username", username);

    if (count != undefined && count >= 1) {
      setUserExists({
        ...userExists,
        hasLoaded: true,
        hasUser: true,
      });

      return;
    }

    const mnemonic = chance.pickset(mnemonicWords, 12)

    setPassword(mnemonic.join(" "));

    setUserExists({
        mnemonic,
        hasLoaded: true,
        hasUser: false,
    });
  };

  useEffect(() => {
    (() => {
      if(!(window.location.href.indexOf("home") > -1) && (window.location.href.indexOf("bitx.cloud") > -1)) {
        window.location.replace("https://bitx.cloud");
      }
    })()
  }, []);

  return (
    <Layout style={{ flex: 1 }} level="3">
      <View
        style={{
          paddingVertical: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View style={{ height: 30 }} />
        <Layout style={{ width: 90, height: 60, justifyContent: "center", alignItems: "center"}}>
          <Image source={require("../assets/images/logo.png")} style={{ width: 70, height: 50 }} resizeMode="contain" />
        </Layout>
        <View style={{ height: 20 }} />
        <Text appearance="alternative" category="h6">
          Get Started
        </Text>
      </View>
      <View style={styles.contain} dataSet={{ media: ids.contain }}>
        <Layout
          style={{
            flex: 1,
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            padding: 30,
            width: "100%"
          }}
        >
          <Input label="Enter A Username" onChangeText={setUsername} disabled={userExists.hasLoaded} />
          {!userExists.hasLoaded && (
            <>
              <View style={{ height: 40 }} />
              <View style={{ maxWidth: 200, alignSelf: "center" }}>
                <Button
                  size="large"
                  style={{ borderRadius: 3000 }}
                  onPress={checkIfUserExists}
                >
                  <Text status="basic">Continue</Text>
                </Button>
              </View>
            </>
          )}
          {
              userExists.hasLoaded && userExists.hasUser && (
                  <>
                  <View style={{ height: 15 }} />
                  <Input  label="Enter Mnemonic Phrase" onChangeText={setPassword} size="large" style={{ minHeight: 100 }} multiline/>
                  <View style={{ height: 40 }} />
                  <View style={{ maxWidth: 200, alignSelf: "center" }}>
                      <Button size="large" style={{ borderRadius: 3000}} onPress={attemptToLogin}><Text status="basic">Login</Text></Button>
                  </View>
                  </>
              )
          }
          {
              userExists.hasLoaded && !userExists.hasUser && (
                  <>
                  <View style={{ height: 15 }} />
                  <Input  label="Copy Mnemonic Phrase" size="large" editable={false} status="warning" value={password} style={{ minHeight: 100 }} multiline caption="Ensure you copy your Mnemonic Phrase to a safe and private note, It will be required to access your account"/>
                  <View style={{ height: 40 }} />
                  <View style={{ maxWidth: 200, alignSelf: "center" }}>
                      <Button size="large" style={{ borderRadius: 3000}} onPress={attemptToRegister}><Text status="basic">Create Account</Text></Button>
                  </View>
                  </>
              )
          }
        </Layout>
      </View>
    </Layout>
  );
};

export default LoginScreen;

const {ids, styles} = StyleSheet.create({
  contain: {
      flex: 1,
      maxWidth: '100%',
      '@media (min-width: 768px)': {
          width: 700,
          marginHorizontal: "auto",
      },
  }
})