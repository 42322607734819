import React from 'react'
import { View, Text } from 'react-native'
import supabase from '../supabase';

export default function useAuth() {

  const [isLoggedIn, setIsLoggedIn] = React.useState(() => {
    const session = supabase.auth.session();

    if (session) {
      return true
    }

    return false
  });

  React.useEffect(() => {
    // (async() => {
    //   const ddd = await supabase.auth.signOut()
    //   console.log(ddd)
    // })()
    supabase.auth.onAuthStateChange((event, session) => {
      if (event == "SIGNED_IN") {
        setIsLoggedIn(true)
      }

      if (event == "SIGNED_OUT" || event == "USER_DELETED") {
        setIsLoggedIn(false)
      }
    })
  }, []);

    return ({isLoggedIn})
}
